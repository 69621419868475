import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["urlField", "urlDisplay", "saveButton", "editButton"];

  connect() {
    console.log("UrlEditorController connected");
  }

  toggleEdit() {
    const isEditing = this.urlFieldTarget.style.display === "block";

    // Toggle visibility of elements based on the current state
    this.urlFieldTarget.style.display = isEditing ? "none" : "block";
    this.urlDisplayTarget.style.display = isEditing ? "block" : "none";
    this.saveButtonTarget.style.display = isEditing ? "none" : "block";
    this.editButtonTarget.textContent = isEditing ? "Modifier" : "Annuler";
  }

  saveUrl() {
    const url = this.urlFieldTarget.value;
    const contentId = this.saveButtonTarget.dataset.id;
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/contents/${contentId}/update_url`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({ url }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.urlDisplayTarget.textContent = url;
          this.toggleEdit(); // Close editing mode
          alert("URL mise à jour avec succès !");
          document.querySelector(
            '[data-target="page-selector.results"]'
          ).style.display = "none";
        } else {
          alert(
            "Erreur lors de la mise à jour de l'URL: " + data.errors.join(", ")
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error updating URL.");
      });
    this.saveButtonTarget.disabled = false;
  }
}
