import { Controller } from "stimulus";
import {
  Chart,
  TimeScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns"; // Date adapter for time-based scales

// Register the required components for Chart.js
Chart.register(
  TimeScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

export default class extends Controller {
  static targets = [
    "clicksChart",
    "impressionsChart",
    "ctrChart",
    "averagePositionsChart",
  ];
  static values = {
    clicksData: Array,
    impressionsData: Array,
    ctrData: Array,
    averagePositionsData: Array,
    eventData: String, // Expecting eventData as a JSON string, will parse to an array
  };

  connect() {
    console.log("charts_controller connected");
    try {
      // Parse eventDataValue and ensure it's an array
      this.eventData = JSON.parse(this.eventDataValue || "[]");
      if (!Array.isArray(this.eventData)) {
        throw new Error("Parsed eventData is not an array");
      }
    } catch (e) {
      this.eventData = []; // Default to an empty array if parsing fails
    }

    // Create charts if data exists
    if (this.hasClicksDataValue) {
      this.createChart(
        this.clicksChartTarget,
        this.clicksDataValue,
        "Clicks",
        "clicks"
      );
    }
    if (this.hasImpressionsDataValue) {
      this.createChart(
        this.impressionsChartTarget,
        this.impressionsDataValue,
        "Impressions",
        "impressions"
      );
    }
    if (this.hasCtrDataValue) {
      this.createChart(this.ctrChartTarget, this.ctrDataValue, "CTR", "ctr");
    }
    if (this.hasAveragePositionsDataValue) {
      this.createChart(
        this.averagePositionsChartTarget,
        this.averagePositionsDataValue,
        "Average Position",
        "average_position"
      );
    }
  }

  createChart(target, data, label, yKey) {
    const ctx = target.getContext("2d");
    // change height of the canvas
    ctx.canvas.height = 100;
    var minDate = new Date(data[0].date);
    // var maxDate = new Date(data[data.length - 1].date);
    const eventAnnotations = this.createAnnotations(minDate); // Generate annotations based on eventData

    new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            label: label,
            data: data.map((d) => ({ x: d.date, y: d[yKey] })), // Use dynamic yKey for the y-axis value
            fill: false,
            borderColor: "blue",
            tension: 0.1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
            },
          },
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          annotation: {
            annotations: eventAnnotations, // Apply event annotations dynamically
          },
        },
      },
    });
  }

  createAnnotations(minDate) {
    if (!Array.isArray(this.eventData)) {
      console.warn("eventData is not an array:", this.eventData);
      return [];
    }

    console.log("Event data:", this.eventData);

    const annotations = {};
    const groupedEvents = this.groupEventsByDate(this.eventData);

    Object.entries(groupedEvents).forEach(([date, events], index) => {
      if (new Date(date) < minDate) return;

      const eventCount = events.length;
      console.log(`Date: ${date}, Event count: ${eventCount}`);

      if (eventCount > 1) {
        annotations[`event-group-${index}`] = {
          type: "line",
          borderColor: "black",
          borderWidth: 5,
          label: {
            enabled: true,
            content: `${eventCount} événements`,
            rotation: 90,
            display: true,
          },
          scaleID: "x",
          value: date,
          click: ({ chart, element }) => {
            const eventList = events
              .map((e) => `${e.date} - ${e.title}`)
              .join("\n");
            alert(eventList);
          },
        };
      } else {
        const event = events[0];
        annotations[`event-${index}`] = {
          type: "line",
          borderColor: "black",
          borderWidth: 3,
          label: {
            enabled: true,
            content: event.title,
            rotation: 90,
            display: true,
          },
          scaleID: "x",
          value: date,
        };
      }
    });

    return annotations;
  }

  groupEventsByDate(events) {
    return events.flat().reduce((acc, event) => {
      const date = event.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(event);
      return acc;
    }, {});
  }
}
