import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["urlInput", "pageId", "results"];

  connect() {
    this.urlInputTarget.addEventListener(
      "keydown",
      this.handleDelete.bind(this)
    );
    this.urlInputTarget.addEventListener("input", this.search.bind(this));
    document.addEventListener("click", this.hideResults.bind(this));
  }

  handleDelete(event) {
    const key = event.key;

    if (key === "Backspace" || key === "Delete") {
      event.preventDefault(); // Prevent the default behavior of deleting one character
      this.urlInputTarget.value = ""; // Clear the entire input value
      this.pageIdTarget.value = ""; // Clear the page ID since the URL is being deleted
      this.resultsTarget.style.display = "none"; // Hide the results if visible
    }
  }

  search(event) {
    const query = event.target.value;

    if (query.length < 2) {
      this.resultsTarget.style.display = "none";
      return;
    }

    fetch(`/pages/search?q=${query}`, {
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.resultsTarget.innerHTML = data
            .map(
              (page) => `
          <a href="#" class="dropdown-item" data-action="click->page-selector#select" data-id="${page.id}" data-url="${page.url}">
            ${page.url}
          </a>
        `
            )
            .join("");
        } else {
          this.resultsTarget.innerHTML = `
          <p class="dropdown-item">
            Aucune page n'éxiste avec cette URL, elle sera utilisée pour en créer une nouvelle: ${query}
          </a>
        `;
        }
        this.resultsTarget.style.display = "block";
      });
  }

  select(event) {
    event.preventDefault();
    const { id, url } = event.currentTarget.dataset;
    this.urlInputTarget.value = url;
    this.pageIdTarget.value = id;
    this.resultsTarget.style.display = "none";
  }

  useNew(event) {
    event.preventDefault();
    this.pageIdTarget.value = ""; // Clear page ID since this is a new page
    this.resultsTarget.style.display = "none";
  }

  hideResults(event) {
    if (!this.element.contains(event.target)) {
      this.resultsTarget.style.display = "none";
    }
  }
}
