import "controllers";
import "chartkick";
import "chart.js";
const Rails = require("rails-ujs");
Rails.start();

import { semrushCampaignFieldSynchroniser } from "../components/semrush-campaign-field-synchroniser";
semrushCampaignFieldSynchroniser();

import {
  initRangeFlatpickr,
  initDateFlatpickr,
} from "../components/init-flatpickr";
initRangeFlatpickr('[data-flatpickr="range"]');
initDateFlatpickr('[data-flatpickr="date"]');
